.top_images {
    position: relative; 
    padding-top: 20px;
    width: 100%; 
    height: 60vh;
    overflow: hidden;
  }
  
  .app_background_image{
    padding-top: 100px;
    position: absolute;
    z-index: -1;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  
  .boddenrestaurant_img {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

@media screen and (max-width: 800px) {
  .top_images {
    position: relative; 
    padding-top: 20px;
    width: 100%; 
    height: 35vh;
    overflow: hidden;
  }
}