.news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.news-item-date {
    text-align: left;
}

.news-items {
    padding-top: 2rem;
}